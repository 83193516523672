<template>
    <div class="content container">
      <div class="shop-info">
        <div class="shop-name"
             style="margin-bottom: 20px">
          <p class="title">店铺名称</p>
          <div>{{ shopInfo.shop_name }}</div>
        </div>
        <div class="shop-name">
          <p class="title">营业开关</p>
          <div>
            <el-switch :disabled="!module_id_array.includes('114')"
                       @change="openSwitch"
                       v-model="shopsWitch">
            </el-switch>
            <div style="color: #999; font-size: 14px;margin-top: 10px">关闭时用户端不显示店铺信息和商品信息，但用户历史订单和收藏、购物车可显示</div>
          </div>
        </div>
        <!-- 店铺主页头像 -->
        <div class="shop-home-photo">
          <p class="title">店铺主页头像</p>
          <div class="home-photo-box">
            <el-upload v-if="module_id_array.includes('106')"
                       :show-file-list="false"
                       :http-request="uploadImg"
                       action="img_id_array">
              <el-button class="btn"
                         type="text">上传本地图片</el-button>

            </el-upload>

            <el-image v-if="shopInfo.shop_logo_str"
                      style="width: 100px; height: 100px;margin:10px 0"
                      :src="shopInfo.shop_logo_str"
                      :preview-src-list="srcList">

            </el-image>
            <img v-else
                 style="width: 100px; height: 100px;margin:10px 0"
                 :src="defaultImg">

            <span style="color: #999; font-size: 14px;">大小在5MB以内，支持png,jpeg,gif格式，最多可上传1张</span>
          </div>
        </div>
        <!-- 店铺主页背景 -->
        <div class="shop-home-photo">
          <p class="title">店铺主页背景</p>
          <div class="home-photo-box">
            <el-upload v-if="module_id_array.includes('114')"
                       :show-file-list="false"
                       :http-request="uploadBgImg"
                       action="img_id_array">
              <el-button class="btn"
                         type="text">上传本地图片</el-button>

            </el-upload>

            <el-image v-if="shopInfo.bg_img_str"
                      style="width: 100px; height: 100px;margin:10px 0"
                      :src="shopInfo.bg_img_str"
                      :preview-src-list="bgsrcList">

            </el-image>
            <img v-else
                 style="width: 100px; height: 100px;margin:10px 0"
                 :src="defaultImg">
            <span style="color: #999; font-size: 14px;">大小在5MB以内，支持png,jpeg,gif格式，最多可上传1张</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { uploadImg, getImgUrl } from '@/service/common';
import home from '@/api/home.js';
export default {
  data() {
    return {
      module_id_array: window.utils.storage.getter('module_id_array') || [],
      shopInfo: {
        shop_name: '',
        shop_logo: '',
        shop_logo_str: '',
        bg_img: '',
        bg_img_str: ''
      },
      shopsWitch: false,
      srcList: [],
      bgsrcList: [],
    };
  },
  computed: {
    defaultImg() {
      return require('../../assets/default_img.png');
    }
  },
  created() {
    if (this.module_id_array.includes('105')) {
      this.getIndexShopInfo();
    }
  },
  methods: {
    getIndexShopInfo() {
      home.getIndexShopInfo().then((res) => {
        let { code, data } = res;
        if (code === 0) {
          this.shopInfo = data.info;
          this.shopsWitch = data.info.business_status === 1 ? true : false;
          this.srcList = [data.info.shop_logo_str];
        }
      });
    },
    // 上传图片
    uploadImg(params) {
      let file = params.file;
      uploadImg(file, 4)
        .then((res) => {
          let { path, pic } = res.data;
          if (res.code === 0) {
            this.shopInfo.shop_logo_str = path;
            this.shopInfo.shop_logo = pic;
            this.srcList = [path];
            let updateParams = {
              shop_name: this.shopInfo.shop_name,
              shop_logo: pic
            };
            this.updateShopLogo(updateParams);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 上传背景图片
    uploadBgImg(params) {
      let file = params.file;
      uploadImg(file, 4)
        .then((res) => {
          let { path, pic } = res.data;
          if (res.code === 0) {
            this.shopInfo.bg_img_str = path;
            this.shopInfo.bg_img = pic;
            this.bgsrcList = [path];
            let updateParams = {
              shop_name: this.shopInfo.shop_name,
              bg_img: pic
            };
            this.updateShopBgimg(updateParams);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 营业开关
    openSwitch() {
      let parmas = {
        shop_name: this.shopInfo.shop_name,
        business_status: this.shopsWitch ? 1 : 0
      };
      home.updateShopBusinessStatus(parmas).then((res) => {
        let { code } = res;
        if (code === 0) {
          this.$message.success('更新营业状态成功');
        }
      });
    },
    // 更新上家图片
    updateShopLogo(parmas) {
      home.updateShopLogo(parmas).then((res) => {
        let { code } = res;
        if (code === 0) {
          this.$message.success('更新店铺主页头像成功');
        }
      });
    }, // 更新店铺背景图
    updateShopBgimg(parmas) {
      home.updateShopBgimg(parmas).then((res) => {
        let { code } = res;
        if (code === 0) {
          this.$message.success('更新店铺背景图成功');
        }
      });
    }
  }
};
</script>

<style lang='scss' scoped>
  .content {
    overflow: inherit;
    .shop-info {
      font-size: 16px;
      .title {
        width: 140px;
      }
      .shop-name {
        display: flex;
        P:last-child {
          margin-left: 20px;
        }
      }
      .shop-home-photo {
        margin-top: 20px;
        display: flex;
        // align-items: flex-start;
        .btn {
          font-size: 15px;
          padding: 0;
        }
        .home-photo-box {
          display: flex;
          flex-direction: column;
        }
      }
      .default-address {
        margin-top: 20px;
      }
    }
}
</style>